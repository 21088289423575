export default {
  title: {
    es: "Base de conocimientos",
    en: "Knowledge Base",
    pt: "Base de conhecimento",
  },
  topicLibrary: {
    es: "Biblioteca de temas",
    en: "Topic Library",
    pt: "Biblioteca de tópicos",
  },
  subtitle: {
    es: "Ver todos los temas creados. Seleccione uno para subir archivos, escribir texto o gestionar su contenido.",
    en: "View all created topics. Select one to upload files, write text, or manage its content.",
    pt: "Veja todos os tópicos criados. Selecione um para enviar arquivos, escrever texto ou gerenciar seu conteúdo.",
  },
  searchTopic: {
    es: "Buscar tema",
    en: "Search topic",
    pt: "Pesquisar tópico",
  },
  createTopic: {
    es: "Crear tema",
    en: "Create topic",
    pt: "Criar tópico",
  },
  edit: {
    title: {
      es: "Editar tema",
      en: "Edit topic",
      pt: "Editar tópico",
    },
    name: {
      es: "Nombre del tema",
      en: "Topic name",
      pt: "Nome do tópico",
    },
  },
  delete: {
    text: {
      es: "¿Estás seguro de que quieres eliminar este tema?",
      en: "Are you sure you want to delete this topic?",
      pt: "Tem certeza de que deseja excluir este tópico?",
    },
    success: {
      es: "El tema fue eliminado correctamente.",
      en: "The topic was deleted.",
      pt: "O tópico foi excluído.",
    },
  },
  table: {
    title: {
      es: "Título del tema",
      en: "Topic title",
      pt: "Título do tópico",
    },
    lastUpdate: {
      es: "Última actualización",
      en: "Last update",
      pt: "Última atualização",
    },
    content: {
      es: "Contenido",
      en: "Content",
      pt: "Conteúdo",
    },
  },
  popUp: {
    edit: {
      es: "Ver y editar contenido",
      en: "View and edit content",
      pt: "Ver e editar conteúdo",
    },
    delete: {
      es: "Eliminar tema",
      en: "Delete topic",
      pt: "Excluir tópico",
    },
    add: {
      es: "Agregar artículo",
      en: "Add article",
      pt: "Adicionar artigo",
    },
    namePlaceholder: {
      es: "Nombre del nuevo tema",
      en: "Name the new topic",
      pt: "Nome do novo tópico",
    },
  },
  validationErrors: {
    name: {
      title: {
        es: "Oops!",
        en: "Oops!",
        pt: "Oops!",
      },
      text: {
        es: "El nombre del tema es obligatorio.",
        en: "The name of the topic is mandatory.",
        pt: "O nome da tópico é obrigatório.",
      },
    },
  },
  topicSuccess: {
    title: {
      es: "Éxito",
      en: "Success",
      pt: "Sucesso",
    },
    text: {
      es: "El tema fue creado correctamente.",
      en: "The topic was created.",
      pt: "A tópico foi criada.",
    },
  },
  content: {
    search: {
      es: "Buscar artículo",
      en: "Search articles",
      pt: "Pesquisar artigo",
    },
    table: {
      title: {
        es: "Título del artículo",
        en: "Article title",
        pt: "Título do artigo",
      },
      type: {
        es: "Fuente",
        en: "Source",
        pt: "Fonte",
      },
    },
    popUp: {
      placeholder: {
        es: "Escribe tu texto aquí...",
        en: "Type your text here...",
        pt: "Digite seu texto aqui...",
      },
      title: {
        en: "Create a new article by writing directly in the editor",
        es: "Cree un nuevo artículo escribiendo directamente en el editor",
        pt: "Crie um novo artigo escrevendo diretamente no editor",
      },
      content: {
        es: "Contenido del artículo",
        en: "Article content",
        pt: "Conteúdo do artigo",
      },
      edit: {
        es: "Editar artículo",
        en: "Edit article",
        pt: "Editar artigo",
      },
      delete: {
        es: "Eliminar artículo",
        en: "Delete article",
        pt: "Excluir artigo",
      },
    },
    success: {
      title: {
        es: "Éxito",
        en: "Success",
        pt: "Sucesso",
      },
      text: {
        es: "El artículo fue creado correctamente.",
        en: "The article was created.",
        pt: "O artigo foi criado.",
      },
    },
    validationErrors: {
      title: {
        es: "Oops!",
        en: "Oops!",
        pt: "Oops!",
      },
      text: {
        es: "El contenido del artículo es obligatorio.",
        en: "The content of the article is mandatory.",
        pt: "O conteúdo do artigo é obrigatório.",
      },
      name: {
        es: "El nombre del artículo es obligatorio.",
        en: "The name of the article is mandatory.",
        pt: "O nome do artigo é obrigatório.",
      },
    },
    delete: {
      text: {
        es: "¿Estás seguro de que quieres eliminar este artículo?",
        en: "Are you sure you want to delete this article?",
        pt: "Tem certeza de que deseja excluir este artigo?",
      },
      success: {
        es: "El artículo fue eliminado correctamente.",
        en: "The article was deleted.",
        pt: "O artigo foi excluído.",
      },
    },
  },
  testAndConfigure: {
    title: {
      es: "Prueba y configura los ajustes del tema",
      en: "Test and configure Topic settings",
      pt: "Teste e configure as configurações do tópico",
    },
    query: {
      es: "Simulación de consulta de usuario",
      en: "User Query Simulation",
      pt: "Simulação de consulta de usuário",
    },
    search: {
      es: "¿Qué es la tarjeta de crédito de seguridad?",
      en: "What is Security credit card?",
      pt: "O que é cartão de crédito de segurança?",
    },
    settings: {
      title: {
        es: "Ajustar configuración de respuesta",
        en: "Adjust Response Settings",
        pt: "Ajustar configurações de resposta",
      },
      confidence: {
        es: "Confianza",
        en: "Confidence",
        pt: "Confiança",
      },
      maximumResults: {
        es: "Resultados máximos a mostrar",
        en: "Maximum results to display",
        pt: "Resultados máximos a serem exibidos",
      },
      resultsPerFile: {
        es: "Resultados máximos por archivo",
        en: "Maximum results per file",
        pt: "Resultados máximos por arquivo",
      },
      button: {
        es: "Ejecutar prueba y guardar ajustes",
        en: "Run test and save settings",
        pt: "Executar teste e salvar configurações",
      }
    },
    results: {
      title: {
        es: "Resultados de la prueba",
        en: "Test Results",
        pt: "Resultados do teste",
      },
      table: {
        name: {
          es: "Nombre del archivo",
          en: "File name",
          pt: "Nome do arquivo",
        },
        results: {
          es: "Resultados coincidentes",
          en: "Matching Results",
          pt: "Resultados correspondentes",
        },
        confidence: {
          es: "Confianza",
          en: "Confidence",
          pt: "Confiança",
        },
        view: {
          es: "Ver",
          en: "View",
          pt: "Ver",
        }
      }
    }
  },
};
