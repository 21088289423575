import { createApp } from 'vue'
import App from './App.vue'
const Vue = createApp(App);

import Vuesax from 'vuesax3'
import 'vuesax3/dist/vuesax.css'
Vue.use(Vuesax, { theme: store.state.themeColors })

import './assets/css/main.css'
import './assets/scss/main.scss'
import './assets/css/icons-style.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import router from './router'
Vue.use(router)

import store from './store/store'
Vue.use(store)

import VueDOMPurifyHTML from 'vue-dompurify-html'
Vue.use(VueDOMPurifyHTML, {
  hooks: {
    beforeSanitizeAttributes: currentNode => {
      // Check if the current node has a 'target' attribute and store its value
      if ('target' in currentNode) {
        const targetAttrValue = currentNode.getAttribute('target')
        currentNode.setAttribute('data-original-target', targetAttrValue)
      }
    },
    afterSanitizeAttributes: currentNode => {
      // Restore the 'target' attribute and add 'rel=noopener' for security
      if (currentNode.hasAttribute('data-original-target')) {
        const targetAttrValue = currentNode.getAttribute('data-original-target')
        currentNode.setAttribute('target', targetAttrValue)
        currentNode.setAttribute('rel', 'noopener')
        currentNode.removeAttribute('data-original-target')
      }
    }
  }
})

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
Vue.use(VueChartkick)

import momentPlugin from './plugins/moment.plugin'
import directivesPlugin from './plugins/directives.plugin'
import imageLoad from './plugins/imageLoad.plugin'
import socketPlugin from './plugins/socket.plugin'
import dialogPlugin from './plugins/dialog.plugin';

Vue.use(momentPlugin)
Vue.use(directivesPlugin)
Vue.use(imageLoad)
Vue.use(socketPlugin)
Vue.use(dialogPlugin)

import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
Vue.use(VueTippy, { directive: 'tooltip'})

import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
Vue.component(VxTooltip.name, VxTooltip)

import VxCard from './components/vx-card/VxCard.vue'
Vue.component(VxCard.name, VxCard)

import VxSlider from './components/vx-slider/VxSlider.vue'
Vue.component('VxSlider', VxSlider)

import VxCheckbox from './components/vx-checkbox/VxCheckbox.vue'
Vue.component('VxCheckbox', VxCheckbox)

import vsTable from './components/vx-table/vsTable.vue';
Vue.component(vsTable.name, vsTable)
import vsTh from './components/vx-table/vsTh.vue';
Vue.component(vsTh.name, vsTh)
import vsTr from './components/vx-table/vsTr.vue';
Vue.component(vsTr.name, vsTr)
import vsTd from './components/vx-table/vsTd.vue';
Vue.component(vsTd.name, vsTd)

import { use } from 'echarts/core'
import { BarChart, LineChart, TreemapChart, HeatmapChart, GaugeChart } from 'echarts/charts'
import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
  TitleComponent,
  VisualMapComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

use([
  LegendComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  TreemapChart,
  VisualMapComponent,
  TitleComponent,
  GaugeChart,
  HeatmapChart
])

Vue.mount('#app')
