import Request from './request'
import state from '../store/state'

const PATH = {
  API_CALL: '/apiCall',
  BOT: '/bot',
  OPTION: '/option',
  BUCKET: '/bucket',
  DIALOG: '/dialog',
  SEARCH_ANSWER_NAME: '/dialog/search-answer',
  TAG_SEARCH: '/tags/search',
  DIALOG_SEARCH: '/dialog/search',
  DIALOG_SEARCH_V2: '/dialog/search-v2',
  INTENT: '/intent',
  USER: '/user',
  COPY_BOT: '/copy',
  QUEUES: '/queue',
  VERSION: '/version',
  TRAIN: '/train',
  FAAS: '/faasfunctions/question-generator',
  TOPICS: '/topics',
  KNOWLEDGE_BASE: '/knowledgeBase',
  ARTICLES: '/articles',
  TEST_TOPIC: '/search',
}

/**
 * Remove an answer and its associated dialog.
 * If the answer can't be removed due to dependencies, it will return a list of those dependencies.
 *
 * @param {String} answerId - The ID of the answer to be removed.
 * @returns {Promise<String | Array<Object>>} when annswer has dependeces
 */
const _removeAnswer = (answerId) => {
  const token = _getToken();
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  };
  const baseUrl = state.config.konecta;
  const url = `${baseUrl}/dialog/answer/${answerId}`;
  return Request.delete(url, config);
};

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

// Bots

/**
 * Get bots
 *
 * @returns {Promise<Array<Bot>>}
 */
const _getBots = (params = {}) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
    ...params
  }

  return Request.get(state.config.konecta + PATH.BOT, config)
}

/**
 * Get Bot
 * @param {String} id the ID of the bot to be loaded
 * @returns {Promise}
 */
const _getBot = id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.BOT + '/' + id, config)
}

/**
 * Create bot
 * @param {Bot} bot the bot to be saved
 * @returns {Promise<Bot>}
 */
const _createBot = bot => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.BOT, bot, config)
}

const copyBot = body => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(`${state.config.konecta}${PATH.BOT}${PATH.COPY_BOT}`, body, config)
}

/**
 * Update Bot
 * @param {Object} bot the bot to the stored
 * @param {String} botId the ID of the bot that is going to be updated
 * @returns {Promise<Bot>}
 */
const _updateBot = (bot, botId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(state.config.konecta + PATH.BOT + '/' + botId, bot, config)
}

/**
 * Create Option
 * @param {Object} option the option to the stored
 * @returns {Promise<Option>}
 */
const _createOption = option => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(state.config.konecta + PATH.OPTION, option, config)
}

/**
 * Update Option
 * @param {Object} option the option to the stored
 * @returns {Promise<Option>}
 */
const _updateOption = (option, id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.OPTION + '/' + id,
    option,
    config
  )
}

const _deleteOption = id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(state.config.konecta + PATH.OPTION + '/' + id, config)
}

/**
 * Get Option
 * @param {Object} option the option to the stored
 * @returns {Promise<Option>}
 */
const _getOption = (botId, type) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(
    state.config.konecta +
    PATH.OPTION +
    '?where[bot]=' +
    botId +
    '&where[type]=' +
    type,
    config
  )
}

/**
 * Delete Bot
 * @param {String} botId the ID of the bot that has to be deleted
 * @returns {Promise}
 */
const _deleteBot = botId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(state.config.konecta + PATH.BOT + '/' + botId, config)
}

// VERSIONS

const _getBotVersions = serviceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.VERSION + '?where[service]=' + serviceId,
    config
  )
}

/**
 * Get active version
 *
 * @param {String} serviceId the ID of the service
 * @return {Promise<[Version]>} a version object
 */
const _getActiveVersion = serviceId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta +
    PATH.VERSION +
    '?where[service]=' +
    serviceId +
    '&where[active]=true',
    config
  )
}

const getBotsActiveVersions = () => {
  const token = _getToken()
  const config = { headers: { authorization: `Bearer ${token}` }}

  return Request.get(
    `${state.config.konecta}${PATH.BOT}/active-version`,
    config
  )
}

/**
 * Train Bot
 * @param {String} versionId the bot version that wants to be trained
 * @returns {Promise}
 */
const _trainVersion = versionId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    state.config.konecta + PATH.VERSION + '/' + versionId + PATH.TRAIN,
    {},
    config
  )
}

/**
 * Create version
 *
 * @param {String} serviceId the service ID
 * @returns {Promise} with a version entity.
 */
const _createVersion = (serviceId, credential, fork) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const data = {
    service: serviceId,
    credential,
    active: true,
    tag: 'prod',
    fork:
      fork && fork.from && fork.from.service && fork.from.version
        ? {
          service: fork.from.service,
          version: fork.from.version,
          intents: fork.intents,
          entities: fork.entities
        }
        : undefined
  }

  return Request.post(state.config.konecta + PATH.VERSION, data, config)
}

// DIALOGS

/**
 * Get Dialog
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _getDialogs = (versionId, parent, dialogId, conditionId = null, isHidden) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = state.config.konecta + PATH.DIALOG + '?where[version]=' + versionId
  if (parent) {
    url += '&where[parent]=' + parent
  }

  if (dialogId) {
    url += '&where[dialogId]=' + dialogId
  }

  if (conditionId) {
    url += '&where[ref_condition]=' + conditionId
  }

  if (isHidden) {
    url += '&where[isHidden]=true'
  }

  return Request.get(
    url,
    config
  )
}

/**
 * Get all answer dialogs
 *
 * @param {String} versionId the version ID
 * @param {String} root the root dialogId
 * @return {Promise<Dialog[]>}
 */
const _getAllAnswerDialogs = (versionId, root) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = state.config.konecta + PATH.DIALOG + '?where[version]=' + versionId
  if (root) {
    url += '&where[root]=' + root
  }
  url += '&limit=5000' // limit 5000

  return Request.get(
    url,
    config
  )
}

/**
 * Get Root Dialogs
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _getAllRootDialogs = ({
  offset = 0,
  limit = 5,
  sortQuery = null,
  rootIdsQuery = [],
  tagsQuery = [],
  newTagsQuery = [],
  name = '',
  nameContains = '',
  serviceId = null,
  ninServiceId = false,
}) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }


  let url = `${state.config.konecta}${PATH.DIALOG}?where[parent][$exists]=false&offset=${offset}&limit=${limit}`

  if (name && name.length > 0) {
    url += `&where[name]=${name}`;
  }

  if (nameContains && nameContains.length > 0) {
    url += `&where[name][contains]=${nameContains}`;
  }

  if (sortQuery && sortQuery.field && sortQuery.value) {
    url += `&sort[${sortQuery.field}]=${sortQuery.value}`
  }

  tagsQuery.forEach(tag => {
    url += `&where[tags.text]=${tag}`;
  })

  newTagsQuery.forEach(tag => {
    url += `&where[tags._idTagCatalog]=${tag}`;
  })

  rootIdsQuery.forEach(rootId => {
    url += `&where[root]=${rootId}`;
  })

  if (serviceId) {
    url += `&where[service]${ninServiceId ? '[$nin]': ''}=${serviceId}`
  }

  return Request.get(
    url,
    config
  )
}


/**
 * Get Root Dialogs
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
 const _getRootDialogs = ({ 
  serviceIds = [],
  versionId,
  offset = 0,
  limit = 5,
  sortQuery,
  tagsQuery = [],
  select = null,
  nameContains = '',
  name = '' 
}, newTagsQuery = []) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = `${state.config.konecta}${PATH.DIALOG}?where[version]=${versionId}&where[parent][$exists]=false&offset=${offset}&limit=${limit}`

  if (serviceIds.length > 0) {
    const whereService = serviceIds.reduce((acc, serviceId) => {
      return `${acc}where[service]=${serviceId}&`
    }, '')
    url = `${state.config.konecta}${PATH.DIALOG}?${whereService}where[parent][$exists]=false&offset=${offset}&limit=${limit}`
  }

  if (sortQuery?.field && sortQuery?.value) {
    url += `&sort[${sortQuery.field}]=${sortQuery.value}`
  }

  if (select) {
    Object.keys(select).forEach(key => {
      url += `&select[${key}]=${select[key]}`
    })
  }

  tagsQuery.forEach(tag => {
    url += `&where[tags.text]=${tag}`;
  })

  newTagsQuery.forEach(tag => {
    url += `&where[tags._idTagCatalog]=${tag}`;
  })

  if (nameContains?.length > 0) {
    url += `&where[name][contains]=${nameContains}`;
  }
  if (name && name.length > 0) {
    url += `&where[name]=${name}`;
  }

  return Request.get(
    url,
    config
  )
}

/**
 * Count Root Dialogs
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _countRootDialogs = ({ versionId, tagsQuery = [] }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = `${state.config.konecta}${PATH.DIALOG}/count?where[version]=${versionId}&where[parent][$exists]=false`

  tagsQuery.forEach(tag => {
    url += `&where[tags.text]=${tag}`;
  })

  return Request.get(
    url,
    config
  )
}

/**
 * Search Root Dialogs
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _searchRootDialogs = ({ versionId = null, offset = 0, limit = 10, q, sortQuery, tagsQuery = [], entity = null }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = `${state.config.konecta}${PATH.DIALOG_SEARCH}?version=${versionId}&offset=${offset}&limit=${limit}`

  if (Array.isArray(versionId)) {
    const whereVersion = versionId.reduce((acc, vId) => {
      return `${acc}version=${vId}&`
    }, '')
    url = `${state.config.konecta}${PATH.DIALOG_SEARCH}?${whereVersion}&offset=${offset}&limit=${limit}`
  }
  if (entity !== null) {
    url = url + `&entity=${entity}`
  }

  if (q && q.length > 0) {
    url += `&q=${q}`;
  }

  if (sortQuery && sortQuery.field && sortQuery.value) {
    url += `&sort=${sortQuery.field.toLowerCase()}`
    if (sortQuery.value === -1) {
      url += ':desc'
    } else {
      url += ':asc'
    }
  }

  tagsQuery.forEach(tag => {
    url += `&tag=${tag}`;
  })

  return Request.get(
    url,
    config
  )
}

/**
 * Searches for root dialogs with the specified parameters.
 *
 * @param {string} urlParams - The URL parameters for the search query.
 * @param {Object} sort - The sorting criteria for the search results. 
 *                        Possible values:
 *                        - {'_updatedat': 'desc'}
 *                        - {'_updatedat': 'asc'}
 *                        - {'name': 'asc'}
 *                        - {'name': 'desc'}
 * @param {number} [page=1] - The page number for pagination (default is 1).
 * @param {number} [pageSize=15] - The number of items per page (default is 15).
 * @returns {Promise} - A promise that resolves to the search results.
 */
const _searchRootDialogsV2 = (urlParams, sort, page = 1, pageSize = 15) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(
    state.config.konecta + PATH.DIALOG_SEARCH_V2 + urlParams + `&sort=${sort}&page=${page}&pageSize=${pageSize}`,
    config
  )
}


/**
 * Search Root Dialogs
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _searchAnswerName = ({ versionId = null, offset = 0, limit = 10, q, sortQuery, tagsQuery = [], entity = null }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = `${state.config.konecta}${PATH.SEARCH_ANSWER_NAME}?version=${versionId}&offset=${offset}&limit=${limit}`

  if (Array.isArray(versionId)) {
    const whereVersion = versionId.reduce((acc, vId) => {
      return `${acc}version=${vId}&`
    }, '')
    url = `${state.config.konecta}${PATH.SEARCH_ANSWER_NAME}?${whereVersion}&offset=${offset}&limit=${limit}`
  }
  if (entity !== null) {
    url = url + `&entity=${entity}`
  }

  if (q && q.length > 0) {
    url += `&q=${q}`;
  }

  if (sortQuery && sortQuery.field && sortQuery.value) {
    url += `&sort=${sortQuery.field.toLowerCase()}`
    if (sortQuery.value === -1) {
      url += ':desc'
    } else {
      url += ':asc'
    }
  }

  tagsQuery.forEach(tag => {
    url += `&tag=${tag}`;
  })

  return Request.get(
    url,
    config
  )
}

/**
 * Search Tags
 *
 * @param {String} versionId the version ID
 * @return {Promise<Dialog[]>}
 */
const _searchTags = ({ versionId, offset = 0, limit = 5000, q }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = `${state.config.konecta}${PATH.TAG_SEARCH}?offset=${offset}&limit=${limit}`;

  if (versionId) {
    url += `&version=${versionId}`;
  }

  if (q && q.length > 0) {
    url += `&q=${q}`;
  }

  url += '&entity=tags'

  return Request.get(
    url,
    config
  )
}

/**
 * Create a dialog
 * @param {Object} dialog the dialog to be saved
 * @returns {Promise<Dialog>}
 */
const _createDialog = (dialog, options) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.DIALOG, { ...dialog, options }, config)
}

/**
 * Update dialog
 * @param {String} dialogId the dialog ID
 * @param {Object} dialog the dialog data that needs to be updated
 * @returns {Promise<Dialog>}
 */
const _updateDialog = (dialogId, dialog, options) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.DIALOG + '/' + dialogId,
    { ...dialog, options },
    config
  )
}

/**
 * Remove dialog
 * @param {String} dialogId the ID of the dialog that wants to be removed
 * @returns {Promise<String>}
 */
const _removeDialog = (dialogId, options) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = state.config.konecta + PATH.DIALOG + '/' + dialogId
  if (options && options.isConditionCatalog) {
    url += '?isConditionCatalog=true'
    if (options.isConditionCatalogId) {
      url += `&isConditionCatalogId=${options.isConditionCatalogId}`
    }
  } else if (options && options.deleteGroup) {
    url += '?deleteGroup=true'
  }

  return Request.delete(url, config)
}

// INTENTS

/**
 * Get intent
 * @param {String} intentId the intent ID [Optional]
 * @returns {Promise<Intent>}
 */
const _getIntentByNameAndVersion = (name, version) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const url = state.config.konecta
    + PATH.INTENT
    + `?where[name]=${name}`
    + `?where[version]=${version}`

  return Request.get(url, config)
}

/**
 * Get intentions per version ID and name optional
 * @param {String} versionId the active version ID
 * @param {String} name then intention name
 * @returns {Promise<Array<Intent>>}
 */
const _getIntentionsPerVersion = (versionId, name = '') => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.INTENT + `?where[version]=${versionId}` + (name.length !== 0 ? `&where[name]=${name}` : ''),
    config
  )
}

/**
 * Create Intent
 * @param {String} versionId the version that the intent will have
 * @param {String} name the name of the intent
 * @param {Array<String>} examples the sample texts that the user can introduce
 * @return {Promise<Intent>}
 */
const _createIntent = (versionId, name, examples, label, suggestionDisable = false) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const data = {
    version: versionId,
    name: name,
    examples: examples,
    label: label || name,
    suggestionDisable,
  }

  return Request.post(state.config.konecta + PATH.INTENT, data, config)
}

/**
 * Update intent
 * @param {String} intentId the intent ID
 * @param {Object} intent an intent
 * @returns {Promise<Intent>}
 */
const _updateIntent = (intentId, intent) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.INTENT + '/' + intentId,
    intent,
    config
  )
}

const _removeIntent = intentId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.INTENT + '/' + intentId,
    config
  )
}

const _getGeneratedExamples = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const data = {
    sentence: payload.intentName,
    num_sequences: 5,
    lang: payload.lang
  }

  return Request.post(state.config.konecta + PATH.FAAS, data, config)
}

/**
 * Get user by ID
 * @param userId {String} the user ID
 * @returns {*|AxiosPromise}
 * @private
 */
const _getUser = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.USER + '/' + userId, config)
}

/**
 * Get users
 * Retrieve all the users
 * @return {Promise<User>}
 */
const _getUsers = query => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.USER + (query || ''), config)
}

/**
 * Create user
 * @param {Object} user the user that wants to be created
 * @return {Promise<User>}
 */
const _createUser = user => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.USER, user, config)
}

/**
 * Delete user
 * @param userId {String} the user ID
 * @returns {*|AxiosPromise}
 * @private
 */
const _deleteUser = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(state.config.konecta + PATH.USER + '/' + userId, config)
}

/**
 * unlock user
 * @param userId {String} the user ID
 * @returns {*|AxiosPromise}
 * @private
 */
const _unlockUser = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.USER}/${userId}/unlock`, null, config)
}

/**
 * lock user
 * @param userId {String} the user ID
 * @returns {*|AxiosPromise}
 * @private
 */
const _blockUser = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.USER}/${userId}/block`, null, config)
}

/**
 * Update user
 * @param {String} id the user ID
 * @param {Object} user the user information that wants to be updated
 * @return {Promise<User>}
 */
const _updateUser = (id, user) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(state.config.konecta + PATH.USER + '/' + id, user, config)
}

/**
 * Update charts user
 * @param {String} id the user ID
 * @param {Object} charts List of charts to be updated
 * @return {Promise<User>}
 */
const _updateChartsUser = (id, charts) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(state.config.konecta + PATH.USER + '/' + id + '/chart', charts, config)
}

/**
 * Expire user password
 * @param {String} userId the user ID that wants to password expired
 * @returns {Promise}
 */
const _expireUserPassword = userId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.USER + '/expire-password' + '/' + userId,
    {},
    config
  )
}

/**
 * Upload a file to the server
 * @param {FormData} data the file that needs to be uploaded
 * @param {String} botId the ID of the bot
 * @returns {Promise<Object>} that contains the URL of the file
 */
const _fileUpload = (data, botId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
    // onUploadProgress: uploadEvent => {
    //   console.warn(
    //     'Progress: ' +
    //     Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
    //     '%'
    //   )
    // }
  }

  return Request.post(
    state.config.konecta + PATH.BUCKET + '/' + botId + '/undefined',
    data,
    config
  )
}

const _getInfoApiCallApp = id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.API_CALL + '/' + id,
    config
  )
}

const _getApiCalls = version => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta + PATH.API_CALL + '?where[version]=' + version,
    config
  )
}

const _createApiCall = apiCall => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.API_CALL, apiCall, config)
}

const _updateApiCall = (apiCallId, apiCall) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.API_CALL + '/' + apiCallId,
    apiCall,
    config
  )
}

const _removeApiCall = apiCallId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.API_CALL + '/' + apiCallId,
    config
  )
}


const _getTopics = ({botId, limit, offset, name = '' }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(
    state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.TOPICS + '/' + botId + '?limit=' + limit + '&offset=' + offset + '&name=' + name,
    config
  )
}


const _createTopic = topic => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.TOPICS, topic, config)
}

const _getArticles = ({topicId, limit, offset, name = '' }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.ARTICLES + '/' + topicId + '?limit=' + limit + '&offset=' + offset + '&title=' + name, config)
}

const _createArticle = article => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.ARTICLES, article, config)
}

const _updateArticle = (articleId, article) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.ARTICLES + '/' + articleId,
    article,
    config
  )
}

const _removeArticle = articleId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.ARTICLES + '/' + articleId,
    config
  )
}

const _updateTopic = (topicId, topic) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.TOPICS + '/' + topicId,
    topic,
    config
  )
}

const _removeTopic = topicId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.TOPICS + '/' + topicId,
    config
  )
}

const _testTopic = (option) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(
    state.config.konecta + PATH.KNOWLEDGE_BASE + PATH.TEST_TOPIC, option, config 
  )
}

// Queues

/**
 * Get queues
 *
 * @returns {Promise<Array<Queues>>}
 */
const _getQueues = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.QUEUES, config)
}

/**
 * Get AI response
 *
 * @returns {Promise<Array<Queues>>}
 */
const _getAIResponse = (option) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(state.config.konecta + PATH.DIALOG + '/botresponse', option, config)
}

export default {
  copyBot,
  createApiCall: _createApiCall,
  createBot: _createBot,
  createDialog: _createDialog,
  createIntent: _createIntent,
  createUser: _createUser,
  createVersion: _createVersion,
  deleteBot: _deleteBot,
  deleteUser: _deleteUser,
  fileUpload: _fileUpload,
  getBotVersions: _getBotVersions,
  getActiveVersion: _getActiveVersion,
  getBotsActiveVersions,
  getApiCalls: _getApiCalls,
  getBot: _getBot,
  getBots: _getBots,
  getAIResponse: _getAIResponse,
  getDialogs: _getDialogs,
  getAllAnswerDialogs: _getAllAnswerDialogs,
  getAllRootDialogs: _getAllRootDialogs,
  getRootDialogs: _getRootDialogs,
  countRootDialogs: _countRootDialogs,
  searchRootDialogs: _searchRootDialogs,
  searchRootDialogV2: _searchRootDialogsV2,
  searchAnswerName: _searchAnswerName,
  searchTags: _searchTags,
  getIntentByNameAndVersion: _getIntentByNameAndVersion,
  getIntentionsPerVersion: _getIntentionsPerVersion,
  getUser: _getUser,
  getUsers: _getUsers,
  removeApiCall: _removeApiCall,
  removeDialog: _removeDialog,
  removeIntent: _removeIntent,
  getGeneratedExamples: _getGeneratedExamples,
  trainVersion: _trainVersion,
  updateApiCall: _updateApiCall,
  updateBot: _updateBot,
  updateDialog: _updateDialog,
  updateIntent: _updateIntent,
  updateUser: _updateUser,
  updateChartsUser: _updateChartsUser,
  expireUserPassword: _expireUserPassword,
  createOption: _createOption,
  updateOption: _updateOption,
  getOption: _getOption,
  deleteOption: _deleteOption,
  getQueues: _getQueues,
  getInfoApiCallApp: _getInfoApiCallApp,
  removeAnswer: _removeAnswer,
  unlockUser: _unlockUser,
  blockUser: _blockUser,
  getTopics: _getTopics,
  createTopic: _createTopic,
  updateTopic: _updateTopic,
  removeTopic: _removeTopic,
  getArticles: _getArticles,
  createArticle: _createArticle,
  updateArticle: _updateArticle,
  removeArticle: _removeArticle,
  testTopic: _testTopic,
}
